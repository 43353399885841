<template>
  <div
    class="price-filters"
    v-if="
      (brFilterCount > 1 || catFilterCount > 1) &&
        isLoggedIn &&
        $route.name != 'Coming Soon' &&
        $route.name != 'Search2'
    "
  >
    <!-- Price Filters -->
    <div class="label-title">
      <p
        class="text-sm uppercase tracking-wide text-brand-body text-opacity-60"
      >
        FILTER BY Price
      </p>
    </div>
    <div class="flex items-center justify-between py-5">
      <span class="text-theme-body font-medium uppercase text-sm"
        >Max Price</span
      >
      <NumberInput
        :value="price"
        :min="minPrice"
        :max="maxPrice"
        @changed="changePrice"
      />
    </div>
    <div class="filter-input">
      <Slider
        class="w-full"
        v-model="price"
        :step="1"
        :min="minPrice"
        :max="maxPrice"
        :tooltips="true"
        showTooltip="drag"
        :format="{
          prefix: '$',
          decimals: 0,
        }"
        tooltipPosition="bottom"
        @change="changePrice"
      />
    </div>
  </div>
</template>

<script>
import { computed, inject } from "vue";
import clone from "lodash/clone";
import Slider from "@vueform/slider";
import "@vueform/slider/themes/default.css";
import { mapGetters, useStore } from "vuex";
import NumberInput from "@/components/NumberInput.vue";
export default {
  name: "PriceFilters",
  components: {
    Slider,
    NumberInput,
  },
  data() {
    return {
      inputPrice: 0,
    };
  },
  methods: {
    changePrice(v) {
      this.$store.dispatch("setPriceRange", v);
      this.$store.dispatch("setPriceCheck", true);
      this.inputPrice = v;
    },
  },
  computed: {
    ...mapGetters({
      price: "getPriceRange",
    }),
    catFilters() {
      if (this.catShowMore) {
        return this.$store.getters.getFilters;
      }
      const filters = clone(this.$store.getters.getFilters);
      return filters.splice(0, 5);
    },
    catFilterCount() {
      return this.$store.getters.getFilters.length;
    },

    brFilters() {
      if (this.brShowMore) {
        return this.$store.getters.getBrandFilter;
      }
      const filters = clone(this.$store.getters.getBrandFilter);
      return filters.splice(0, 5);
    },
    brFilterCount() {
      return this.$store.getters.getBrandFilter.length;
    },
  },
  watch: {
    inputPrice(v) {
      this.$store.dispatch("setPriceRange", v);
      this.$store.dispatch("setPriceCheck", true);
    },
  },
  setup() {
    const axios = inject("axios");
    const store = useStore();
    const minPrice = computed(() => store.getters.getMinPrice);
    const maxPrice = computed(() => store.getters.getMaxPrice);

    return {
      isLoggedIn: axios.isLoggedIn,
      minPrice,
      maxPrice,
    };
  },
};
</script>
<style lang="scss" scoped>
.price-filters {
  @apply w-full list-none px-6 select-none -mt-4;

  .label-title {
    @apply border-t border-gray-200 border-opacity-40 pt-4;
  }

  &.hide-title {
    @apply px-0;
    .label-title {
      @apply hidden;
    }
  }

  .filter-input {
    @apply w-full pb-3 pt-0 px-2;
    --slider-connect-bg: #3b82f6;
    --slider-tooltip-bg: #3b82f6;
    --slider-handle-ring-color: #3b82f630;
    --slider-height: 5px;
    --slider-radius: 2px;
    --slider-handle-width: 12px;
    --slider-handle-height: 12px;

    --slider-tooltip-font-size: 12px;
    --slider-tooltip-line-height: 1.5;
    --slider-tooltip-font-weight: 500;
    --slider-tooltip-min-width: 20px;
    --slider-tooltip-bg: #2b3040;
    --slider-tooltip-bg-disabled: #bfbfbf;
    --slider-tooltip-color: #fff;
    --slider-tooltip-radius: 2px;
    --slider-tooltip-py: 1px;
    --slider-tooltip-px: 5px;
    --slider-tooltip-arrow-size: 5px;
    --slider-tooltip-distance: 4px;
  }
}
</style>
