<template>
  <div class="number-input" v-bind="$attrs">
    <span>$</span>
    <input
      :id="id"
      :value="value"
      :min="min"
      :max="max"
      type="number"
      ref="input"
      spellcheck="false"
      autocomplete="off"
      @change="inputChange($event.target.value)"
    />
    <div class="btns">
      <button @click="add" :disabled="value == max">
        <ChevronUpIcon />
      </button>
      <button @click="subs" :disabled="value < 1">
        <ChevronDownIcon />
      </button>
    </div>
  </div>
</template>

<script>
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/vue/outline";

export default {
  name: "NumberInput",
  inheritAttrs: false,
  components: {
    ChevronUpIcon,
    ChevronDownIcon,
  },
  props: {
    id: {
      type: Number || String,
      default: 0,
    },
    value: {
      type: Number,
      default: 0,
    },
    step: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 500,
    },
  },
  methods: {
    emitValue(v) {
      this.$emit("changed", v);
    },
    inputChange(v) {
      v = parseInt(v);
      if (v >= this.min && v <= this.max) {
        this.emitValue(v);
      } else {
        this.$refs.input.value = this.value;
      }
    },
    add() {
      let val = this.value;
      if (val < this.max) {
        val = val + this.step;
        this.emitValue(val);
      }
    },
    subs() {
      let val = this.value;
      if (val > this.min) {
        val = val - this.step;
        this.emitValue(val);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.error-info {
  @apply absolute inset-x-0 bottom-0.5 transform translate-y-full text-center leading-none rounded-b-md p-1 whitespace-nowrap text-xxs text-brand-primary bg-brand-ash bg-opacity-50;
  font-size: 11px;

  &.slide-fade-enter-active {
    transition: all 0.3s ease-out;
  }

  &.slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  }

  &.slide-fade-enter-from,
  &.slide-fade-leave-to {
    @apply bottom-full opacity-0;
  }
}

.number-input {
  @apply flex items-center bg-white rounded px-2 py-0.5 border-2 border-brand-ash border-opacity-50 hover:border-brand-ash z-10 relative;
  min-width: 100px;

  &.transparent-bg {
    @apply bg-transparent;
  }

  &.transparent-border {
    @apply border-transparent;
  }

  > span {
    @apply text-md text-theme-dark;
  }

  &.medium {
    @apply px-2 py-1.5 text-base;
  }

  input {
    @apply flex-grow bg-transparent text-sm appearance-none block leading-none rounded text-brand-secondary focus:outline-none border-none focus:ring-0 text-left py-0 px-1;

    @include inputPlaceholder() {
      @apply text-sm text-brand-secondary leading-snug;
    }
    @include resetAutoComplete() {
      @apply text-sm text-brand-secondary leading-snug;
    }

    &:disabled {
      @apply bg-brand-smoke bg-opacity-5 cursor-default;
      &:hover {
        @apply border-brand-smoke;
      }
    }
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  .btns {
    @apply flex flex-col items-center justify-between px-0.5;
  }
  button {
    @apply inline-block font-light text-lg text-brand-body w-3 focus:outline-none cursor-pointer hover:text-brand-secondary uppercase leading-none;

    &:disabled {
      @apply opacity-30 cursor-default hover:opacity-30;
    }
  }
}
</style>

<style scoped>
::-webkit-input-placeholder {
  text-transform: initial;
}

:-moz-placeholder {
  text-transform: initial;
}

::-moz-placeholder {
  text-transform: initial;
}

:-ms-input-placeholder {
  text-transform: initial;
}
</style>
