<template>
  <div class="brand-banner max-container">
    <div class="banner-head" v-if="$slots.header">
      <slot name="header" />
    </div>
    <div class="banner-content">
      <div v-if="$slots.logo" class="brand-image">
        <slot name="logo" />
      </div>
      <div v-else-if="logo" class="brand-image">
        <img :src="logo" :alt="title" />
      </div>
      <div v-else class="brand-image">
        <Icon name="watchlist-heart" class="w-16 h-16" />
      </div>
      <div class="brand-info">
        <span class="block w-10 h-1 bg-white mb-3"></span>
        <p
          class="text-xl text-white font-normal text-opacity-40"
          v-if="pretext"
        >
          {{ pretext }}
        </p>
        <h2 class="text-2xl md:text-4xl font-semibold text-white mb-2">
          {{ title }}
        </h2>
        <p
          class="text-xl text-white font-medium text-opacity-60"
          v-if="subtitle"
        >
          {{ subtitle }}
        </p>
        <div
          class="flex flex-col md:flex-row md:items-center md:space-x-8 space-y-2 md:space-y-0"
          v-if="moreInfo"
        >
          <p
            class="text-base md:text-xl text-white font-normal text-opacity-60"
          >
            {{
              items
                ? items
                : `${getProductCount} ${
                    getProductCount > 1 ? "products" : "product"
                  }`
            }}
          </p>
          <div v-if="isLoggedIn" class="flex items-center space-x-2">
            <Switch
              v-model="enabled"
              v-if="toggle"
              class="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none"
            >
              <span class="sr-only">Show Stocks</span>
              <span
                aria-hidden="true"
                class="pointer-events-none absolute bg-transparent w-full h-full rounded-md"
              />
              <span
                aria-hidden="true"
                :class="[
                  enabled ? 'bg-brand bg-opacity-60' : 'bg-white bg-opacity-60',
                  'pointer-events-none absolute h-3 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200',
                ]"
              />
              <span
                aria-hidden="true"
                :class="[
                  enabled
                    ? 'translate-x-5 bg-brand'
                    : 'translate-x-0 bg-gray-200',
                  'pointer-events-none absolute left-0 inline-block h-5 w-5 rounded-full bg-gray-200 shadow transform ring-0 transition-transform ease-in-out duration-200',
                ]"
              />
            </Switch>
            <span
              class="text-md md:text-base text-white font-normal text-opacity-60"
              v-if="toggle"
              >In Stock Only</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="banner-footer" v-if="$slots.footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { Switch } from "@headlessui/vue";
import { mapGetters } from "vuex";
import Categories from "@/modules/Categories";
export default {
  name: "BrandBanner",
  components: {
    Switch,
  },
  props: {
    title: String,
    logo: String,
    items: String,
    toggle: Boolean,
    pretext: String,
    subtitle: String,
    moreInfo: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(["getProductCount"]),
  },
  setup(_, context) {
    const enabled = ref(false);
    watch(enabled, function(v) {
      context.emit("enabled", v);
    });
    const { isLoggedIn } = Categories();
    return {
      enabled,
      isLoggedIn,
    };
  },
};
</script>
<style lang="scss" scoped>
.brand-banner {
  @apply flex flex-col w-full;

  .banner-head {
    @apply block w-full mb-8 text-xs text-brand-body uppercase;
  }

  .banner-content {
    @apply flex space-x-8;

    .brand-image {
      @apply rounded-md shadow-box bg-white p-4 flex items-center justify-center w-28 h-28 md:w-36 md:h-36;

      img {
        @apply w-auto h-auto max-w-full;
      }
    }
  }
  // .banner-footer {
  //   @apply -mt-4 flex justify-end text-white;
  // }
}
.categories-banner {
  .brand-image {
    background-color: #2c364f;
  }
}
.trending-banner {
  .brand-image {
    background-color: #908983 !important;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.04);
  }

  .brand-info {
    p,
    h2 {
      @apply text-black;
    }

    p {
      @apply text-opacity-50;
    }

    > span {
      @apply bg-black bg-opacity-50;
    }
  }
}
.lightning-banner.brand-banner {
  .banner-content {
    .brand-image {
      @apply w-24 h-24;
      background-color: #4b3677 !important;
    }
  }
}
</style>
