<template>
  <div class="member-info-card">
    <BlurryWrap class="blurry-container">
      <div class="card-inner">
        <div
          class="flex items-center space-x-2 text-theme-body leading-none text-sm"
        >
          <Icon name="star" />
          <span>Members Only</span>
        </div>
        <h3>
          {{ title }}
        </h3>
        <p>
          {{ description }}
        </p>
        <ul
          class="features grid grid-cols-1 sm:grid-cols-2 gap-3 py-2"
          v-if="features.length"
        >
          <li class="flex flex-col space-y-1.5" v-for="f in features" :key="f">
            <div class="flex items-center space-x-2">
              <Icon name="checkbox-black" class="w-5 h-5" />
              <span class="text-base text-theme-secondary font-medium">
                {{ f.title }}
              </span>
            </div>
            <span class="text-sm font-normal text-theme-body">{{
              f.text
            }}</span>
          </li>
        </ul>
        <div class="pt-2">
          <Button
            class="medium"
            :title="button"
            @click="$router.push('/join-us')"
          ></Button>
        </div>
      </div>
    </BlurryWrap>
  </div>
</template>

<script>
export default {
  name: "MemberInfoCard",
  props: {
    title: {
      type: String,
      default: "Learn what sells best in your area & around the country",
    },
    description: {
      type: String,
      default: "This ensures you're up to date on the latest trends",
    },
    features: {
      type: Array,
      default: () => [],
    },
    button: {
      type: String,
      default: "Join Medusa",
    },
  },
};
</script>
<style lang="scss" scoped>
.member-info-card {
  @apply flex w-full h-full py-24 z-50 overflow-hidden;

  .blurry-container {
    @apply flex items-start pt-12 md:pt-24 justify-center -inset-x-5 px-8 md:px-10;
    z-index: 99;
  }

  .card-inner {
    @apply flex flex-col space-y-3 w-full max-w-lg bg-white rounded-md shadow-box px-7 md:px-10 py-8;

    h3 {
      @apply text-xl md:text-2xl font-semibold text-theme-secondary;
    }

    p {
      @apply text-md text-theme-body font-medium;
    }
  }
}
</style>
