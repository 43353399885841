<template>
  <div class="items-list-card">
    <div class="items-list-card-inner">
      <div class="item">
        <div class="item-img loader-block" />
        <div class="item-info">
          <p class="loader-block"><em>XXXXXX XXX XXXX XXXX XXXX</em></p>
          <span class="loader-block"><em>XXXXXX</em></span>
        </div>
      </div>
      <div class="item-option-btn">
        <p class="loader-block"><em>XXXXX XXX</em></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListLoader",
};
</script>
<style lang="scss" scoped>
.loader-block {
  em {
    @apply inline-block text-transparent bg-gray-100 animate-pulse rounded-md text-sm;
  }
  &.item-img {
    @apply bg-gray-100 animate-pulse rounded-md;
  }
}
</style>
