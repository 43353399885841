<template>
  <div
    v-if="items.data.data"
    :class="['items-listing-container', { merge: width < 1280 }]"
  >
    <template v-if="width >= 1280">
      <div class="disclosure-items-list">
        <template
          v-for="(item, index) in items.data.data"
          :key="item.name + '-' + index"
        >
          <ItemDropdown
            v-if="index % 2 == 0"
            :item="item"
            :position="index + 1"
            :list_id="route.name == 'Search2' ? route.query.q : null"
          />
        </template>
      </div>
      <div class="disclosure-items-list">
        <template
          v-for="(item, index) in items.data.data"
          :key="item.name + '-' + index"
        >
          <ItemDropdown
            v-if="index % 2" 
            :item="item" 
            :position="index + 1" 
            :list_id="route.name == 'Search2' ? route.query.q : null" 
          />
        </template>
      </div>
    </template>
    <template v-else>
      <div class="disclosure-items-list">
        <ItemDropdown
          v-for="(item, index) in items.data.data"
          :key="item.name + '-' + index"
          :item="item"
          :position="index + 1"
          :list_id="route.name == 'Search2' ? route.query.q : null"
        />
      </div>
    </template>
  </div>
  <div v-else-if="!loading && msgShow" class="flex justify-center items-center">
    Sorry! No products match this criteria
  </div>
</template>

<script>
import ItemDropdown from "./ItemDropdown.vue";
import Categories from "@/modules/Categories";
import Segments from "@/modules/Segments";
import { useRoute } from "vue-router";
import { onUpdated } from "vue";
import { useWindowSize } from "@vueuse/core";

export default {
  name: "BrandItemsList",
  components: {
    ItemDropdown,
  },
  props: {
    items: Object,
    filter: Array,
    filter_by: String,
    loading: Boolean,
  },
  data() {
    return {
      msgShow: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.msgShow = true;
    }, 2000);
  },
  updated() {
    if (this.items.data.hasOwnProperty("data")) {
      this.$store.dispatch("productCountAction", this.items.data.total);
    }
  },
  unmounted() {
    this.$store.dispatch("productCountAction", 0);
  },
  setup(props) {
    const { width } = useWindowSize();
    const route = useRoute();
    const { isLoggedIn } = Categories();
    const {
      ProductListViewed,
      ProductListViewedNonLoggedIn,
      ProductListFiltered,
      ProductListViewedTS
    } = Segments();

    onUpdated(() => {
      let from_index = props?.items?.data?.current_page * 12 - 12 || 0;
      let new_items =
        props?.items?.data?.data?.slice(
          from_index,
          props?.items?.data?.data?.length
        ) || [];

      if (props.items.data.hasOwnProperty("data")) {
        let list_id = route.name == "Search2" ? route.query.q : null;
        if (isLoggedIn) {
          from_index = from_index + 1;
          if (props.filter.length > 0){
            ProductListFiltered(
              route,
              new_items,
              props.filter,
              props.filter_by,
              list_id,
              from_index
            );
          }
          else ProductListViewed(route, new_items, list_id, from_index);

          // List Viewed for Top Sort
          ProductListViewedTS(new_items);
        } else {
          ProductListViewedNonLoggedIn(route, new_items, list_id, from_index);
        }
      }
    });

    return {
      isLoggedIn,
      width,
      route
    };
  },
};
</script>

<style lang="scss">
.items-listing-container {
  @apply grid grid-cols-1 xl:grid-cols-2 gap-5;
}
</style>