<template>
  <div class="brand w-full flex-grow pb-10">
    <div
      class="collection-banner-container bg-full-image"
      :style="
        `background-color: #EEE5DB; background-image: url('/images/trending-banners/${getBgImage}.svg')`
      "
    >
      <Banner
        :title="
          isLoggedIn
            ? pincode
              ? pincode.state_name
              : state
              ? state.state_name
              : ''
            : 'Your Area'
        "
        :items="' '"
        pretext="Trending in"
        logo="../images/trending.svg"
        class="trending-banner"
      >
        <template #header>
          <ol role="list" class="breadcrumb">
            <li>
              <div class="breadcrumb-item">
                <router-link to="/">Home</router-link>
              </div>
            </li>
            <li>
              <div class="breadcrumb-item">
                <span>/&nbsp;</span><a href="#">Trending</a>
              </div>
            </li>
          </ol>
        </template>
        <template #footer>
          <!-- Footer Slot -->
        </template>
      </Banner>
    </div>

    <!-- Listing -->
    <TabGroup as="div" class="py-10 space-y-10 max-container relative">
      <MemberInfoCard v-if="!isLoggedIn" :features="memberfeatures" />
      <TabList
        as="div"
        class="flex flex-col-reverse 2xl:flex-row 2xl:items-center 2xl:justify-between"
        :class="!isLoggedIn ? 'blurry-content' : ''"
      >
        <div
          class="flex items-center w-full 2xl:w-1/2 border-b border-brand-body border-opacity-30 space-x-3"
        >
          <Tab v-slot="{ selected }">
            <button
              class="bg-transparent border-0 border-b-2 px-2 py-2.5"
              :class="[
                selected
                  ? 'border-brand-primary text-brand-secondary'
                  : 'text-brand-body  text-opacity-60 border-transparent',
              ]"
              @click="toggleTab()"
            >
              NOT PURCHASED
            </button>
          </Tab>
          <Tab v-slot="{ selected }">
            <button
              class="bg-transparent border-0 border-b-2 px-2 py-2.5"
              :class="[
                selected
                  ? 'border-brand-primary text-brand-secondary'
                  : 'text-brand-body  text-opacity-60 border-transparent',
              ]"
              @click="toggleTab()"
            >
              ALL PRODUCTS
            </button>
          </Tab>
        </div>

        <!-- Sort Filters -->
        <div
          class="flex-grow w-full sm:w-auto flex flex-col md:flex-row items-center 2xl:justify-end gap-5 mb-8 2xl:mb-0 max-w-md sm:max-w-2xl mx-auto"
          v-if="pincode || state"
        >
          <div
            class="flex items-center text-sm font-normal uppercase tracking-wide text-brand-body"
          >
            <span>Filter By</span>
          </div>
          <PopoverGroup
            class="w-full sm:w-auto flex flex-col sm:flex-row items-stretch sm:items-center bg-white rounded-lg divide-y sm:divide-y-0 sm:divide-x divide-brand-ash divide-opacity-50 z-10"
          >
            <!-- {{pincode.data}} -->
            <!-- <Listbox as="div" v-model="pincode" class="relative z-10 inline-block text-left" @click="fetchData()"> -->
            <Listbox
              as="div"
              v-model="pincode"
              class="w-full sm:w-auto relative z-50 inline-block text-left cursor-pointer"
            >
              <ListboxButton
                class="w-full sm:w-auto relative inline-flex items-center justify-between py-2 pl-2 pr-2 text-left text-sm font-normal bg-white rounded cursor-pointer text-brand-secondary focus:outline-none rounded-l-lg border-0"
                style="min-width:12rem"
              >
                <Icon
                  name="location"
                  class="flex-shrink-0 h-5 w-5 text-brand-body mr-2"
                  aria-hidden="true"
                />
                <p class="w-full p-0 text-sm">
                  {{ pincode ? pincode.state_name : state.state_name }}
                </p>
              </ListboxButton>

              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <ListboxOptions class="states-list z-50">
                  <li class="w-full px-2 pt-2 py-3">
                    <form class="relative w-full" autocomplete="off">
                      <input
                        id="searchstate"
                        name="search-state"
                        class="appearance-none block text-xs font-light w-full pl-5 pr-3 py-1.5 border border-none rounded-full leading-5 bg-brand-ash bg-opacity-20 placeholder-text focus:outline-none sm:text-sm focus:ring-brand-smoke"
                        placeholder="Search State"
                        type="search"
                        autocomplete="chrome-off"
                        v-model="searchState"
                      />
                      <div
                        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                      >
                        <SearchIcon
                          class="h-4 w-4 text-brand-ash"
                          aria-hidden="true"
                        />
                      </div>
                    </form>
                  </li>
                  <ListboxOption
                    as="template"
                    v-for="st in getAllStates"
                    :key="st.id"
                    :value="st"
                    v-slot="{ active }"
                  >
                    <li
                      :class="[
                        active
                          ? 'text-theme-secondary bg-gray-100'
                          : 'text-gray-900',
                        'select-none relative py-2 pl-3 pr-9 cursor-pointer',
                      ]"
                    >
                      <div class="flex items-center">
                        <span
                          :class="[
                            (pincode ? pincode.state_name : state.state_name) ==
                            st.state_name
                              ? 'font-bold'
                              : 'font-medium',
                            ' ml-3 block truncate',
                          ]"
                        >
                          {{ st.state_name }}
                        </span>
                      </div>
                      <span
                        v-if="
                          (pincode ? pincode.state_name : state.state_name) ==
                            st.state_name
                        "
                        :class="[
                          active
                            ? 'text-theme-secondary'
                            : 'text-theme-secondary',
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                        ]"
                      >
                        <Icon
                          name="location"
                          class="flex-shrink-0 h-5 w-5 text-brand-body mr-2"
                          aria-hidden="true"
                        />
                      </span>
                    </li>
                  </ListboxOption>
                </ListboxOptions>
              </transition>
            </Listbox>

            <Popover
              as="div"
              class="w-full sm:w-auto relative z-40 inline-block text-left"
            >
              <PopoverButton
                class="w-full sm:w-32 relative inline-flex items-center justify-between py-2 pl-4 pr-4 text-left text-sm font-normal bg-white rounded cursor-pointer text-brand-secondary focus:outline-none"
              >
                <span>{{
                  selectedbrands.length
                    ? selectedbrands.length + " Brands"
                    : "Brand"
                }}</span>
                <ChevronDownIcon
                  class="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-brand-body"
                  aria-hidden="true"
                />
              </PopoverButton>

              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <PopoverPanel
                  class="w-80 origin-top-right absolute right-0 mt-2 bg-white rounded shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                >
                  <div
                    class="space-y-3 p-3 border-b border-brand-ash border-opacity-60"
                  >
                    <form class="relative w-full" autocomplete="off">
                      <input
                        id="searchbrand"
                        v-model="qryB"
                        name="search-brand"
                        class="appearance-none block text-xs font-light w-full pl-5 pr-3 py-1.5 border border-none rounded-full leading-5 bg-brand-ash bg-opacity-20 placeholder-text focus:outline-none sm:text-sm focus:ring-brand-smoke"
                        placeholder="Search brand"
                        type="search"
                        autocomplete="chrome-off"
                      />
                      <div
                        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                      >
                        <SearchIcon
                          class="h-4 w-4 text-brand-ash"
                          aria-hidden="true"
                        />
                      </div>
                    </form>
                    <div
                      class="-m-1 flex flex-wrap items-center"
                      v-if="selectedbrands.length"
                    >
                      <span
                        v-for="activeFilter in selectedbrands"
                        :key="activeFilter.id"
                        class="m-1 inline-flex rounded-full border border-gray-200 items-center py-1.5 pl-3 pr-2 text-xs font-medium bg-white text-brand-body"
                      >
                        <span>{{ activeFilter.name }}</span>
                        <button
                          type="button"
                          class="flex-shrink-0 ml-1 h-4 w-4 p-1 rounded-full inline-flex text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                          @click="removeBrandBadge(activeFilter)"
                        >
                          <svg
                            class="h-2 w-2"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 8 8"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-width="1.5"
                              d="M1 1l6 6m0-6L1 7"
                            />
                          </svg>
                        </button>
                      </span>
                    </div>
                  </div>
                  <div class="space-y-4 p-4 max-h-25 overflow-auto">
                    <div
                      v-for="option in qryB
                        ? brandslist.data.filter(
                            (r) =>
                              r.name.toLowerCase().search(qryB.toLowerCase()) >=
                              0
                          )
                        : brandslist.data"
                      :key="option.id"
                      class="flex items-center justify-between"
                    >
                      <div>
                        <input
                          :id="`brand-${option.id}`"
                          name="brandfilter"
                          v-model="selectedbrands"
                          @change="changeBrand()"
                          :value="option"
                          type="checkbox"
                          class="h-4 w-4 border-2 border-brand-secondary opacity-60 rounded text-brand-secondary focus:ring-transparent"
                        />
                        <label
                          :for="`brand-${option.id}`"
                          class="ml-3 pr-6 text-sm font-medium text-brand-secondary text-opacity-70 whitespace-nowrap"
                        >
                          {{ option.name }}
                        </label>
                      </div>
                      <span class="text-xs text-brand-body text-opacity-50">{{
                        option.stock
                      }}</span>
                    </div>
                  </div>
                </PopoverPanel>
              </transition>
            </Popover>
            <Popover
              as="div"
              class="w-full sm:w-auto relative z-30 inline-block text-left"
            >
              <PopoverButton
                class="w-full sm:w-36 relative inline-flex items-center justify-between py-2 pl-4 pr-4 text-left text-sm font-normal bg-white rounded cursor-pointers text-brand-secondary focus:outline-none rounded-r-lg"
              >
                <span>{{
                  selectedCategories.length
                    ? selectedCategories.length + " Categories"
                    : "Category"
                }}</span>
                <ChevronDownIcon
                  class="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-brand-body"
                  aria-hidden="true"
                />
              </PopoverButton>

              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <PopoverPanel
                  class="w-80 origin-top-right absolute right-0 mt-2 bg-white rounded shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                >
                  <div
                    class="space-y-3 p-3 border-b border-brand-ash border-opacity-60"
                  >
                    <form class="relative w-full" autocomplete="off">
                      <input
                        id="searchcat"
                        v-model="qryC"
                        name="search-cat"
                        class="appearance-none block text-xs font-light w-full pl-5 pr-3 py-1.5 border border-none rounded-full leading-5 bg-brand-ash bg-opacity-20 placeholder-text focus:outline-none sm:text-sm focus:ring-brand-smoke"
                        placeholder="Search Categories"
                        type="search"
                        autocomplete="chrome-off"
                      />
                      <div
                        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                      >
                        <SearchIcon
                          class="h-4 w-4 text-brand-ash"
                          aria-hidden="true"
                        />
                      </div>
                    </form>
                    <div
                      class="-m-1 flex flex-wrap items-center"
                      v-if="selectedCategories.length"
                    >
                      <span
                        v-for="activeFilter in selectedCategories"
                        :key="activeFilter.id"
                        class="m-1 inline-flex rounded-full border border-gray-200 items-center py-1.5 pl-3 pr-2 text-xs font-medium bg-white text-brand-body"
                      >
                        <span>{{ activeFilter.name }}</span>
                        <button
                          type="button"
                          class="flex-shrink-0 ml-1 h-4 w-4 p-1 rounded-full inline-flex text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                          @click="removeCatBadge(activeFilter)"
                        >
                          <svg
                            class="h-2 w-2"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 8 8"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-width="1.5"
                              d="M1 1l6 6m0-6L1 7"
                            />
                          </svg>
                        </button>
                      </span>
                    </div>
                  </div>
                  <div class="space-y-4 p-4 max-h-25 overflow-auto">
                    <div
                      v-for="option in qryC
                        ? catslist.data.filter(
                            (r) =>
                              r.name.toLowerCase().search(qryC.toLowerCase()) >=
                              0
                          )
                        : catslist.data"
                      :key="option.id"
                      class="flex items-center justify-between"
                    >
                      <div>
                        <input
                          :id="`cat-${option.id}`"
                          name="catfilter"
                          v-model="selectedCategories"
                          @change="changeCategory()"
                          :value="option"
                          type="checkbox"
                          class="h-4 w-4 border-2 border-brand-secondary opacity-60 rounded text-brand-secondary focus:ring-transparent"
                        />
                        <label
                          :for="`cat-${option.id}`"
                          class="ml-3 pr-6 text-sm font-medium text-brand-secondary text-opacity-70 whitespace-nowrap"
                        >
                          {{ option.name }}
                        </label>
                      </div>
                      <span class="text-xs text-brand-body text-opacity-50">{{
                        option.stock
                      }}</span>
                    </div>
                  </div>
                </PopoverPanel>
              </transition>
            </Popover>
          </PopoverGroup>
        </div>
      </TabList>

      <TabPanels :class="!isLoggedIn ? 'blurry-content' : ''">
        <!-- Grids -->
        <div class="grid grid-cols-1 xl:grid-cols-2 gap-6">
          <!-- Best Sellers -->
          <div>
            <h2
              class="text-xl font-semibold text-brand-primary inline-flex items-center mb-5"
            >
              <Icon name="trend" class="w-6 h-6 mr-2 -mb-1" />
              <span>Best Selling</span>
              <tippy interactive>
                <span class="cursor-pointer opacity-40 hover:opacity-60"
                  ><Icon name="info" class="w-5 h-5 ml-3"
                /></span>
                <template #content>
                  <p class="mb-2">
                    Best selling products are the ones with the highest quantity
                    of sales
                  </p>
                </template>
              </tippy>
            </h2>

            <!-- Listing -->
            <div class="space-y-6">
              <template v-if="trendBest.loading">
                <ListLoader v-for="x in 5" :key="x" />
              </template>
              <div
                v-for="(item, index) in trendBest.data"
                :key="item.name + '-' + index"
              >
                <ItemDropDown :item="item" :position="index + 1" :rank="index + 1" :list_id="'best-selling'" />
              </div>
              <div
                v-if="!trendBest.loading && trendBest.data.length == 0"
                class="flex justify-center items-center"
              >
                Sorry! No products match this criteria
              </div>
            </div>
          </div>

          <!-- Most Popular -->
          <div>
            <h2
              class="text-xl font-semibold text-brand-primary inline-flex items-center mb-5"
            >
              <Icon name="fire" class="w-6 h-6 mr-2" />
              <span>Most Popular</span>
              <tippy interactive>
                <span class="cursor-pointer opacity-40 hover:opacity-60"
                  ><Icon name="info" class="w-5 h-5 ml-3"
                /></span>
                <template #content>
                  <p class="mb-2">
                    Most popular products are the ones that the most shops are
                    purchasing
                  </p>
                </template>
              </tippy>
            </h2>

            <!-- Listing -->
            <div class="space-y-6">
              <template v-if="trendUnique.loading">
                <ListLoader v-for="x in 5" :key="x" />
              </template>
              <div
                v-for="(item, index) in trendUnique.data"
                :key="item.name + '-' + index"
              >
                <ItemDropDown :item="item" :position="index + 1" :rank="index + 1" :list_id="'most-popular'" />
              </div>
              <div
                v-if="!trendUnique.loading && trendUnique.data.length == 0"
                class="flex justify-center items-center"
              >
                Sorry! No products match this criteria
              </div>
            </div>
          </div>
        </div>
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script>
import { computed, onMounted, onUpdated, ref } from "vue";
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  TabGroup,
  TabList,
  Tab,
  TabPanels,
  // TabPanel,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { ChevronDownIcon, SearchIcon } from "@heroicons/vue/solid";
import Banner from "../common/Banner.vue";
import Trends from "@/modules/Trending";
import ItemDropDown from "../common/ItemDropdown.vue";
import { useStore } from "vuex";
import ListLoader from "@/components/loaders/ListLoader.vue";
import MemberInfoCard from "@/components/MemberInfoCard.vue";
import { useRoute } from "vue-router";

export default {
  name: "Categories",
  components: {
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    // TabPanel,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    ChevronDownIcon,
    SearchIcon,
    Banner,
    ItemDropDown,
    ListLoader,
    MemberInfoCard,
  },
  data() {
    return {
      qryB: "",
      qryC: "",
    };
  },
  beforeMount() {
    this.$store.dispatch("getCart", { ax: null });
  },
  setup() {
    const store = useStore();
    const {
      states,
      getStates,
      brands,
      getbrands,
      categories,
      getCategories,
      getTrends,
      trendBest,
      trendUnique,
      isLoggedIn,
    } = Trends();
    getStates();
    const statesList = states;
    const brandslist = brands;
    const catslist = categories;
    var state = computed(() => store.getters.getSelectedState);
    const pincode = ref();
    const searchState = ref("");

    const getSelectedAddress = computed(() => store.getters.getSelectedAddress);

    const selectedbrands = ref([]);
    const selectedCategories = ref([]);

    getbrands(selectedCategories);
    getCategories(selectedbrands);

    const memberfeatures = [
      {
        title: "Best Selling",
        text: "Products that sell the most",
      },
      {
        title: "Most Popular",
        text: "Products that the most shops carry",
      },
    ];

    var tabToggle = true;

    function changeBrand() {
      // selectedCategories.value = []
      fetchData();
      getCategories(selectedbrands);
    }
    function changeCategory() {
      // selectedbrands.value = []
      fetchData();
      getbrands(selectedCategories);
    }
    function toggleTab() {
      tabToggle = !tabToggle;
      fetchData(tabToggle);
    }

    const route = useRoute();
    function fetchData() {
      let brnd = selectedbrands.value;
      let cat = selectedCategories.value;
      let params = {
        notPurchased: tabToggle,
        state: pincode.value ? pincode.value.state : state.value.state,
        category: cat.length > 0 ? cat.map((r) => r.id).join(",") : "",
        brand: brnd.length > 0 ? brnd.map((r) => r.id).join(",") : "",
      };
      trendBest.data = [];
      trendUnique.data = [];
      let email = route.params ? route.params.email : null;
      getTrends(params, [...brnd, ...cat], email);
    }

    const getAllStates = computed(() => {
      if (!searchState.value) {
        return states.data;
      }
      return states.data.filter((s) =>
        s.state_name.toLowerCase().includes(searchState.value.toLowerCase())
      );
    });

    // onMounted(() => {
    //   console.log(state.value)
    //   if (state.value) {
    //     console.log(state.value)
    //     selectedbrands.value = [];
    //     selectedCategories.value = [];
    //     fetchData();
    //   }
    // });
    onUpdated(() => {
      if (state.value) {
        selectedbrands.value = [];
        selectedCategories.value = [];
        fetchData();
      }
    });
    function removeBrandBadge(badge) {
      selectedbrands.value = selectedbrands.value.filter(
        (r) => r.id != badge.id
      );
      getCategories(selectedbrands);
      fetchData();
    }
    function removeCatBadge(badge) {
      selectedCategories.value = selectedCategories.value.filter(
        (r) => r.id != badge.id
      );
      getbrands(selectedCategories);
      fetchData();
    }

    const availableImages = [
      "alabama",
      "alaska",
      "arizona",
      "arkansas",
      "california",
      "colorado",
      "connecticut",
      "default",
      "delaware",
      "florida",
      "georgia",
      "hawaii",
      "idaho",
      "illinois",
      "indiana",
      "iowa",
      "kansas",
      "kentucky",
      "louisiana",
      "maine",
      "maryland",
      "massachusetts",
      "michigan",
      "minnesota",
      "mississippi",
      "missouri",
      "montana",
      "nebraska",
      "nevada",
      "newhampshire",
      "newjersey",
      "newmexico",
      "newyork",
      "northcarolina",
      "northdakota",
      "ohio",
      "oklahoma",
      "oregon",
      "pennsylvania",
      "rhodeisland",
      "southcarolina",
      "southdakota",
      "tennessee",
      "texas",
      "utah",
      "vermont",
      "virginia",
      "washington",
      "westvirginia",
      "wisconsin",
      "wyoming",
    ];

    const availableImagesCodes = {
      AL: "alabama",
      AK: "alaska",
      AZ: "arizona",
      AR: "arkansas",
      CA: "california",
      CO: "colorado",
      CT: "connecticut",
      DE: "delaware",
      FL: "florida",
      GA: "georgia",
      HI: "hawaii",
      ID: "idaho",
      IL: "illinois",
      IN: "indiana",
      IA: "iowa",
      KS: "kansas",
      KY: "kentucky",
      LA: "louisiana",
      ME: "maine",
      MD: "maryland",
      MA: "massachusetts",
      MI: "michigan",
      MN: "minnesota",
      MS: "mississippi",
      MO: "missouri",
      MT: "montana",
      NE: "nebraska",
      NV: "nevada",
      NH: "newhampshire",
      NJ: "newjersey",
      NM: "newmexico",
      NY: "newyork",
      NC: "northcarolina",
      ND: "northdakota",
      OH: "ohio",
      OK: "oklahoma",
      OR: "oregon",
      PA: "pennsylvania",
      RI: "rhodeisland",
      SC: "southcarolina",
      SD: "southdakota",
      TN: "tennessee",
      TX: "texas",
      UT: "utah",
      VT: "vermont",
      VA: "virginia",
      WA: "washington",
      WV: "westvirginia",
      WI: "wisconsin",
      WY: "wyoming",
    };

    const getBgImage = computed(() => {
      if (!getSelectedAddress.value?.state && !pincode.value?.state_name) {
        return "default";
      }

      const selectedPlace =
        pincode.value?.state_name || getSelectedAddress.value.state;
      let place = selectedPlace.replace(" ", "").toLowerCase();
      if (place.length < 3) {
        place = availableImagesCodes[place.toUpperCase()];
      }
      return availableImages.includes(place) && isLoggedIn ? place : "default";
    });

    return {
      state,
      statesList,
      pincode,
      brandslist,
      catslist,
      selectedbrands,
      changeBrand,
      selectedCategories,
      changeCategory,
      toggleTab,
      trendBest,
      trendUnique,
      fetchData,
      removeBrandBadge,
      removeCatBadge,
      isLoggedIn,
      getBgImage,
      memberfeatures,
      searchState,
      getAllStates,
    };
  },
};
</script>

<style lang="scss" scoped>
.collection-banner-container {
  &.bg-full-image {
    @apply bg-cover xl:bg-contain bg-no-repeat bg-right xl:bg-center;

    @media (max-width: 600px) {
      @apply bg-right-bottom;
      background-size: auto 70% !important;
      &[style*="default.svg"] {
        background-size: 100% !important;
      }
    }

    &[style*="default.svg"] {
      @apply bg-right-bottom;
      background-size: 100%;
    }
  }
}

.items-content-panel {
  @apply w-full px-6 py-6 space-y-2;
  background: rgba(62, 60, 142, 0.02);
}
.states-list {
  @apply absolute  mt-1 bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm w-60 right-0;
  @include CssScrollbar();
}
.max-h-25 {
  @include CssScrollbar();
  max-height: 20rem;
}
</style>

<style lang="scss">
.items-list-card {
  @apply overflow-visible;

  .items-list-card-inner {
    border-radius: 7px;

    .ranking-bubble {
      &:empty {
        @apply hidden;
      }
      @apply w-8 h-8 border-4 border-theme-bg rounded-full flex-shrink-0 absolute left-0 -ml-4 top-1/2 -mt-4 text-xxs font-normal bg-white flex items-center justify-center text-theme-body;
    }
  }
}
</style>
