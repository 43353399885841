import { inject, reactive, onMounted, onUnmounted } from "vue";
import { store } from "../store";
import { useRoute } from "vue-router";

export default function Common(name, { LoginURL, LogoutURL }) {
  const axios = inject("axios");
  const route = useRoute();
  const products = reactive({
    loading: false,
    onScrollLoading: false,
    data: [],
    error: "",
    isLoadingScrollData: false,
  });

  let page = 1;
  let sort = "latest";
  let stock = "false";

  async function getFiltersData() {
    let dataToPost = {
      ...name,
      stock,
      min: 0,
      max: store.getters.getPriceRange,
    };
    await axios.api
      .get("/filterOptions", { params: dataToPost })
      .then((res) => {
        let loggedIn = axios.isLoggedIn;
        store.dispatch("updateOptions", res.data.data.categories.filter(c => loggedIn || c.login_required == '0'));
        store.dispatch("updateBrandFilter", res.data.data.brands.filter(b => loggedIn || b.login_required == '0'));
      });
  }

  async function getData(load) {
    let d = {};
    const brand = store.getters.getSelected
      .filter((r) => r.type == "brand")
      .map((r) => r.id);
    const category = store.getters.getSelected
      .filter((r) => r.type == "category")
      .map((r) => r.id);
    const price = store.getters.getPriceCheck ? store.getters.getPriceRange : 0;
    let dataToPost = {
      page,
      stock,
      sort,
      brand,
      category,
      min: 0,
      max: price,
    };
    products[load] = true;
    if (axios.isLoggedIn) {
      await axios.authApi.get(LoginURL, { params: dataToPost }).then((res) => {
        d = res.data.data;
      });
    } else {
      await axios.api.get(LogoutURL, { params: dataToPost }).then((res) => {
        d = res.data.data;
      });
    }

    products[load] = false;
    return d;
  }
  const reset = () => {
    products.data = [];
    page = 1;
  };
  async function onSortChanged(v) {
    reset();
    sort = v;

    // Temporary condition
    if(!sort && route.params.slug == "420-sale-collection"){
      store.dispatch("setIsRank", true);
      sort = 'popularity';
    }
    products.data = await getData("loading");
  }

  async function onStockChanged(v) {
    reset();
    stock = v;
    // maxPrice(stock);
    store.dispatch("updateOptions", []);
    store.dispatch("updateBrandFilter", []);
    store.dispatch("removeOption", "clear");
    getFiltersData();
  }

  async function onFilterChanged(v) {
    const brand = store.getters.getSelected
      .filter((r) => r.type == "brand")
      .map((r) => r.id);
    const category = store.getters.getSelected
      .filter((r) => r.type == "category")
      .map((r) => r.id);
    reset();
    maxPrice(stock, category, brand);
    products.data = await getData("loading");
  }
  async function onPriceRangeChanged(v) {
    reset();
    products.data = await getData("loading");
    // store.dispatch("removeOption",'clear');
    // getFiltersData();
  }

  async function maxPrice(stock = null, category = [], brand = []) {
    let url = route.params.slug
      ? "/maxPrice?collection_slug=" + route.params.slug
      : "/maxPrice";
    await axios.authApi
      .get(url, {
        params: {
          stock: stock,
          brand: brand,
          category: category,
        },
      })
      .then((res) => {
        if (res.data.status) {
          store.dispatch("setPriceRange", Number(res.data.data.max_price));
          store.dispatch("setMaxPrice", Number(res.data.data.max_price));
          store.dispatch("setPriceCheck", false);
        }
      });
  }

  async function handleScroll() {
    const itemsContainer = document.querySelector(".items-listing-container");
    if (!itemsContainer) {
      return;
    }
    const canLoadMore = page < products.data.last_page;
    const elemScrollHeight = itemsContainer.scrollHeight;
    const elemScrollLoadHeight = elemScrollHeight - elemScrollHeight * 0.5;
    const shouldLoadMore =
      window.pageYOffset + 200 > elemScrollLoadHeight ||
      elemScrollHeight < window.innerHeight ||
      window.pageYOffset + elemScrollLoadHeight < window.innerHeight;

    if (canLoadMore && shouldLoadMore && !products.isLoadingScrollData) {
      page++;
      products.isLoadingScrollData = true;
      const res = await getData("onScrollLoading");
      products.data.current_page = page;
      products.data.data?.push(...res.data);
      products.isLoadingScrollData = false;
    }
  }

  // this will register the event when the component is mounted on the DOM
  onMounted(() => {
    getFiltersData();
    window.addEventListener("scroll", handleScroll);
  });
  // We then unregister the listener when the component is removed from the DOM
  onUnmounted(() => window.removeEventListener("scroll", handleScroll));

  return {
    products,
    getFiltersData,
    getData,
    onSortChanged,
    onStockChanged,
    onFilterChanged,
    onPriceRangeChanged,
    maxPrice,
  };
}
