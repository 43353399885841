<template>
  <div class="grid grid-col-1 xl:grid-cols-2 gap-5 mb-5" v-if="isLoading">
    <ListLoader v-for="i in items" :key="i" />
  </div>
</template>

<script>
import ListLoader from "./ListLoader.vue";

export default {
  name: "ListLoaderWrapper",
  components: {
    ListLoader,
  },
  props: {
    items: {
      type: Number,
      default: 8,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
