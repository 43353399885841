import { inject, onMounted, onUnmounted, reactive } from 'vue';
import { useRoute } from 'vue-router'

export default function Watchlist() {

    const axios = inject('axios');

    var page = 1;
    let lastPage = 0;
    let load = false;

    const watchlist = reactive({
        loading: false,
        data: [],
        error: '',
        loadingScroll: false
    })
    const getWatchlist = async() => {
        if (axios.isLoggedIn) {
            watchlist.loading = true;
            load = true;
            axios.authApi.get('/watchList', {
                params: {
                    page: page
                }
            }).then((resp) => {
                if (resp.status) {
                    watchlist.data = resp.data.data;
                    lastPage = resp.data.data.last_page;
                    load = false;
                } else {
                    watchlist.error = resp.data.error;
                }
            }).catch(function(error) {
                console.log(error);
            }).finally(() => (watchlist.loading = false));
        } else {}
    };

    const setWatchlist = (product) => {
        if (product.watchlist == null) {
            axios.authApi.post('/addWatchlist', {
                product_id: product.id
            }).then((resp) => {
                if (resp.status) {
                    return true;
                }
            }).catch(function(error) {
                console.log(error);
            });
        } else {
            axios.authApi.get('/removeWatchlist', {
                params: {
                    product_id: product.id

                }
            }).then((resp) => {
                if (resp.status) {
                    return false;
                }
            }).catch(function(error) {
                console.log(error);
            });
        }
    };

    const handleScroll = () => {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            if (page < lastPage && !load) {
                page++;
                watchlist.loadingScroll = true;
                load = true;
                axios.authApi.get('/watchList', {
                    params: {
                        page: page,
                    }
                }).then((resp) => {
                    if (resp.status) {
                        for (let i in resp.data.data.data) {
                            watchlist.data.data.push(resp.data.data.data[i]);
                        }
                        lastPage = resp.data.data.last_page;
                        load = false;
                    } else {
                        watchlist.error = resp.data.error;
                    }
                }).catch(function(error) {
                    console.log(error);
                }).finally(() => (watchlist.loadingScroll = false));
            }
        }
    }

    // this will register the event when the component is mounted on the DOM
    onMounted(() => {
        window.addEventListener('scroll', handleScroll)
    });

    // We then unregister the listener when the component is removed from the DOM
    onUnmounted(() => {
        window.removeEventListener('scroll', handleScroll)
    });

    return {
        watchlist,
        setWatchlist,
        getWatchlist,
        isLoggedIn: axios.isLoggedIn
    }

}