<template>
  <div class="floating-filter-pill divide-x divide-gray-200">
    <!-- Sorting -->
    <Listbox v-model="selectedOption" as="template" :disabled="!isLoggedIn">
      <div class="filter-listing">
        <ListboxButton
          class="inline-flex items-center justify-center px-3 leading-none"
          :class="isLoggedIn ? '' : 'opacity-70'"
        >
          <Icon
            :name="isLoggedIn ? 'sort' : 'lock-filled'"
            :class="
              isLoggedIn ? 'w-4 h-4 mr-2 -mt-0.5' : 'w-3.5 h-3.5 mr-2 -mt-0.5'
            "
          />
          <span>Sort</span>
        </ListboxButton>
        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <ListboxOptions class="filter-listing-container">
            <ListboxOption
              v-slot="{ active, selected }"
              v-for="opt in optionsList"
              :key="opt.name"
              :value="opt"
              as="template"
            >
              <li :class="[selected || active ? 'active' : '']">
                <p
                  :class="[
                    selected ? 'font-medium' : 'font-normal',
                    'block truncate',
                  ]"
                >
                  {{ opt.name }}
                </p>
                <span v-if="opt.description">{{ opt.description }}</span>
                <span v-if="selected" class="check-mark">
                  <CheckIcon aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
    <!-- Filters -->
    <PopoverGroup as="template">
      <Popover
        as="div"
        class="relative z-10 inline-block text-left"
        v-slot="{ open }"
        :class="{ 'pointer-events-none': open }"
      >
        <PopoverButton
          class="inline-flex items-center justify-center  px-3 focus:outline-none leading-none -mb-0.5"
        >
          <Icon name="sort" class="w-4 h-4 mr-2" />
          <span class="-mb-0.5">Filter</span>
        </PopoverButton>

        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <PopoverPanel class="filter-pill-content fill-height">
            <div class="filter-tabs">
              <div
                class="filter-tab-nav"
                :class="{
                  'two-cols':
                    $route.name == 'CatSlug' || $route.name == 'BrdSlug',
                }"
              >
                <template v-for="tab in filterTabs" :key="tab.value">
                  <button
                    v-if="
                      !(
                        tab.value == 'categories' && $route.name == 'CatSlug'
                      ) && !(tab.value == 'brands' && $route.name == 'BrdSlug')
                    "
                    :class="{ active: tab.value == activeTab }"
                    @click="activeTab = tab.value"
                  >
                    {{ tab.name }}
                  </button>
                </template>
              </div>
              <div class="filter-tab-contents">
                <div
                  class="filter-tab-content price"
                  v-show="activeTab == 'price'"
                >
                  <PriceFilter class="hide-title" />
                </div>
                <div
                  class="filter-tab-content categories"
                  v-show="activeTab == 'categories'"
                >
                  <CategoryFilters
                    :disableBorder="true"
                    :disableShowMore="true"
                  />
                </div>
                <div
                  class="filter-tab-content brands"
                  v-show="activeTab == 'brands'"
                >
                  <BrandFilters :disableBorder="true" :disableShowMore="true" />
                </div>
              </div>
            </div>
          </PopoverPanel>
        </transition>
      </Popover>
    </PopoverGroup>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref, watch } from "vue";
import Brands from "@/modules/Brands";
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/solid";
import { useStore } from "vuex";
import PriceFilter from "@/views/layouts/modules/PriceFilter.vue";
import CategoryFilters from "@/views/layouts/modules/CategoryFilters.vue";
import BrandFilters from "@/views/layouts/modules/BrandFilters.vue";
import { useRoute } from "vue-router";

const filterTabs = [
  {
    name: "Brands",
    value: "brands",
  },
  {
    name: "Categories",
    value: "categories",
  },
  {
    name: "Price",
    value: "price",
  },
];

const defaultOptions = [
  {
    name: "Newest Products",
    value: "latest",
    description:
      '"New Products" are products that are the newest in our catalog',
  },
  {
    name: "Most Popular",
    value: "popularity",
    description:
      '"Most Popular" are products that the most shops are purchasing',
  },
  {
    name: "Best Selling",
    value: "best-selling",
    description:
      '"Best Selling" are products with the highest quantity of sales',
  },
  {
    name: "Alphabetical Sort",
    value: "name",
    description: "Products sorted from A to Z",
  },
  {
    name: "Lowest Price",
    value: "price-low",
    description: "Products sorted by the lowest price first",
  },
  {
    name: "Highest Price",
    value: "price-high",
    description: "Products sorted by the highest price first",
  },
];

export default {
  name: "SortFilterMobile",
  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    CheckIcon,
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    PriceFilter,
    CategoryFilters,
    BrandFilters,
  },
  props: {
    options: {
      type: Array,
      default: () => defaultOptions,
    },
  },
  emits: ["onSort"],
  setup(props, { emit }) {
    const store = useStore();
    const i = store.getters.getIsRank ? 1 : 0;
    const selectedOption = ref(props.options[i]);
    const optionsList = ref([]);
    const activeTab = ref("brands");
    const route = useRoute();

    onMounted(() => {
      optionsList.value = props.options;
      if (route.name == "BrdSlug") {
        activeTab.value = "categories";
      }
    });
    onUnmounted(() => {
      store.dispatch("setIsRank", false);
    });

    watch(selectedOption, (current) => {
      if (current.value) {
        store.dispatch("setSortValue", current.value);
        emit("onSort", current.value);
      }
    });
    const { isLoggedIn } = Brands();
    return {
      selectedOption,
      optionsList,
      isLoggedIn,
      activeTab,
      filterTabs,
    };
  },
};
</script>

<style lang="scss" scoped>
.filter-listing {
  @apply relative flex items-center space-x-4 flex-shrink-0;
}
.filter-listing-container {
  @apply overflow-auto text-base z-50 divide-y divide-gray-100 h-screen origin-bottom-left md:origin-top-right fixed md:absolute left-0 bottom-16 right-3 md:bottom-auto md:left-auto md:right-0 mt-2 bg-white rounded-md shadow-md md:shadow-2xl p-0 ring-1 ring-black ring-opacity-5 focus:outline-none w-auto min-w-0 mb-1.5;
  max-height: 55vh;
  @include CssScrollbar();

  li {
    @apply flex flex-col select-none relative py-3 pr-6 pl-4 text-brand-primary cursor-pointer;
    &.active {
      @apply text-brand-primary bg-brand-smoke;
    }
    p {
      @apply text-sm;
    }
    span {
      @apply text-xxs  text-theme-body font-normal leading-normal;
      &.check-mark {
        @apply absolute inset-y-0 right-0 flex items-start pt-3 justify-center pr-3 text-brand;
        svg {
          @apply w-3.5 h-3.5;
        }
      }
    }
  }
}
.filter-listing-btn {
  @apply relative py-2 pl-4 pr-8 text-left font-normal bg-black bg-opacity-50 rounded cursor-pointer focus:outline-none text-xs sm:text-sm ring-1 ring-white ring-opacity-20;
  min-width: 200px;

  &.disabled {
    @apply cursor-default;
  }
}
.filter-listing-label {
  @apply flex items-center text-xs md:text-sm font-light uppercase tracking-wide;
  svg {
    @apply w-5 h-5 mr-2;
  }
}

.filter-tabs {
  @apply flex flex-col w-full items-stretch h-full;

  .filter-tab-nav {
    @apply w-full grid grid-cols-3 rounded-md overflow-hidden flex-shrink-0;
    &.two-cols {
      @apply grid-cols-2;
    }

    button {
      @apply flex items-center justify-center w-full px-2 py-3 font-light leading-none bg-gray-50 text-black text-sm;

      &.active {
        @apply font-medium bg-theme-primary bg-opacity-10;
      }
    }
  }

  .filter-tab-contents {
    @apply flex flex-col py-3 flex-grow;

    .filter-tab-content {
      @apply w-full py-2 overflow-auto;
      max-height: 45vh;
      @include CssScrollbar();

      &.price {
        @apply px-5;
      }
    }
  }
}
</style>
